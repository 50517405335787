// strings.js

const strings = {
    STRIPE_DEV_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/test_7sIg013cV8BH8yA4h5", // vfr
    STRIPE_PROD_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/7sIcNF8ZwgjY3wAeVI", // vfr

    STRIPE_SUBSCRIPTION_MANAGEMENT_URL: "https://billing.stripe.com/p/login/28ocP200L31J9gs7ss",
    SUBSCRIPTION_STATUS_API_URL: "https://funquiz.app/modular2/subscription-status",
    
    GENERIC_PAGE_TITLE: "Increase Video Framerate 🎨",
    HOME_PAGE_TITLE: "Home - Increase Video Framerate 🎨",
    DASHBOARD_PAGE_TITLE: "Home - Increase Video Framerate 🎨",
    WELCOME_MESSAGE_H1_TITLE: "Welcome to Increase Video Framerate 🎨",
    FX_DESCRIPTION_MESSAGE: "This f(x) can be used to increase video frame rate, choose a video and then press the 'Submit Input' button below to get your result!",
    
    MAIN_API_NAME: "video_framerate_ai_1",
    MAIN_API_URL: "https://funquiz.app/modular2/video-framerate-ai-1",

    PROD_CLERK_PUB_KEY: "pk_live_Y2xlcmsueW9sbGVnLmNvbSQ",
    DEV_CLERK_PUB_KEY: "pk_test_aGFybWxlc3MtYmVlLTY5LmNsZXJrLmFjY291bnRzLmRldiQ",

    // ... add more strings as needed
};



export default strings;